import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "100px 0 100px 0",
	"background": "#ffffff",
	"quarkly-title": "FAQ-5"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 600 42px/1.2 --fontFamily-sans",
			"color": "--darkL1",
			"children": "FAQ (Frequently Asked Questions)"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 70px 0px",
			"font": "normal 300 20px/1.5 --fontFamily-sansHelvetica",
			"color": "#555a5f",
			"sm-margin": "0px 0px 50px 0px",
			"children": "Navigate through PrimeDrive Rentals's most commonly asked questions to gain insights on our product offerings, ordering process and shipping."
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(2, 1fr)",
			"grid-gap": "50px 50px",
			"md-grid-template-columns": "1fr",
			"sm-grid-gap": "35px 0"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 25px/1.2 --fontFamily-sans",
			"color": "--darkL1",
			"children": "What kind of products do you offer?"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"color": "#555a5f",
			"children": "We provide a curated selection of football equipment, trainers, and accessories tailored specifically for gyms, courts, and training arenas."
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 25px/1.2 --fontFamily-sans",
			"color": "--darkL1",
			"children": "How do I place an order?"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"color": "#555a5f",
			"children": "Simply reach out to us via our contact form or email, and our team will guide you through the ordering process."
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 25px/1.2 --fontFamily-sans",
			"color": "--darkL1",
			"children": "Do you offer bulk or custom orders?"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"color": "#555a5f",
			"children": "Absolutely! Please contact our team with your requirements, and we'll be more than happy to assist."
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 25px/1.2 --fontFamily-sans",
			"color": "--darkL1",
			"children": "Where can I find the price list and product lineup?"
		}
	},
	"text9": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"color": "#555a5f",
			"children": "For the most up-to-date pricing and product information, please send us an email. We'll promptly provide you with all the necessary details."
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text10": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 25px/1.2 --fontFamily-sans",
			"color": "--darkL1",
			"children": "What are your shipping policies?"
		}
	},
	"text11": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"color": "#555a5f",
			"children": "We pride ourselves on prompt deliveries. Specific shipping details and costs can be discussed when placing an order."
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text12": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 25px/1.2 --fontFamily-sans",
			"color": "--darkL1",
			"children": "Can I return or exchange products?"
		}
	},
	"text13": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"color": "#555a5f",
			"children": "Yes, we have a flexible return and exchange policy. Please refer to our 'Return Policy' page for detailed information."
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text14": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 25px/1.2 --fontFamily-sans",
			"color": "--darkL1",
			"children": "How can I track my order?"
		}
	},
	"text15": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"color": "#555a5f",
			"children": "Once your order is dispatched, we will provide you with tracking information via email."
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text16": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 25px/1.2 --fontFamily-sans",
			"color": "--darkL1",
			"children": "Do you offer international shipping?"
		}
	},
	"text17": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"color": "#555a5f",
			"children": "Yes, we cater to both domestic and international clients. Shipping rates and times may vary based on the destination."
		}
	}
};

const Faq = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Text {...override("text")} />
		<Text {...override("text1")} />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text2")} />
				<Text {...override("text3")} />
			</Box>
			<Box {...override("box2")}>
				<Text {...override("text4")} />
				<Text {...override("text5")} />
			</Box>
			<Box {...override("box3")}>
				<Text {...override("text6")} />
				<Text {...override("text7")} />
			</Box>
			<Box {...override("box4")}>
				<Text {...override("text8")} />
				<Text {...override("text9")} />
			</Box>
			<Box {...override("box5")}>
				<Text {...override("text10")} />
				<Text {...override("text11")} />
			</Box>
			<Box {...override("box6")}>
				<Text {...override("text12")} />
				<Text {...override("text13")} />
			</Box>
			<Box {...override("box7")}>
				<Text {...override("text14")} />
				<Text {...override("text15")} />
			</Box>
			<Box {...override("box8")}>
				<Text {...override("text16")} />
				<Text {...override("text17")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Faq, { ...Section,
	defaultProps,
	overrides
});
export default Faq;